import React, {useContext, useEffect} from "react";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import {Box, Button, TextField} from "@mui/material/";
import SendIcon from '@mui/icons-material/Send';
import PropTypes, {InferProps} from "prop-types";
import {styled} from "@mui/material/styles";
import {Api} from "../../../../Api";
import {ActivityContext} from "../../ActivityContext";
import {AppContext} from "../../../../AppContext";
import {enableApproval} from "../../methods";
const {REACT_APP_ACTIVITY_API} =  process.env;
export const StyledPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(2) ,
    paddingBottom: theme.spacing(3),
    display: 'flex',
    overflow: 'auto',
    margin: 'auto auto',
    marginTop: 'calc(10% - 10px)',
    marginBottom: '40px',
    flexDirection: 'column',
    width: '400px',
    minHeight: '100px'
}))

export const Approval: any = ({lastMessage}: InferProps<typeof Approval.propTypes>) => {
    const {setWaitingForBackendMessage} = useContext(AppContext);
    const {activity, isEditor, isArchived}  = useContext(ActivityContext);
    const [approvalCodes] = React.useState(activity.approvalCodes??[]);
    const [shouldEnableApproval] = React.useState(enableApproval(activity));
    useEffect(() => {
        if (lastMessage !== null) {
            if (JSON.parse(lastMessage.data).event === 'APPROVE_TEMPLATES') {
                setTimeout( () => {
                    setWaitingForBackendMessage('');
                }, 2500)
            }
        }

    }, [lastMessage]);

    const ApprovalFormCountryVersion = ({country}: {country?: string}) => {
        const isDefaultTemplate = React.useRef(!country)
        const approvalCode = React.useRef(isDefaultTemplate.current ? activity.approvalCode : activity.approvalCodes.find( (aCode: any) => aCode.country === country)?.approvalCode)
        const label = country??'';
        const buttonLabel = approvalCode.current ? 'Update' : 'Save';
        const handleSubmitCountryVersion = async (event: any) => {
            event.preventDefault();
            const data = new FormData(event.currentTarget);
            let result = {approvalCode: ''};
            // @ts-ignore
            for(const pair of data.entries()) {
                result = {...result, [pair[0]]: pair[1]}
            }
            if ( result.approvalCode ) {
                setWaitingForBackendMessage('Preparing templates, this can take up to two minutes ');
                let status = activity.status === 2 ? activity.status + 1 : activity.status;
                await Api( {
                    name: 'APPROVE_TEMPLATES',
                    type: 'PUT',
                    url: `${REACT_APP_ACTIVITY_API}activity`,
                    body: {
                        action: 'APPROVE_TEMPLATES',
                        payload: {
                            _id: activity._id,
                            isApproved: true,
                            status,
                            approvalCode: isDefaultTemplate.current ? result.approvalCode : activity.approvalCode,
                            approvalCodes: isDefaultTemplate.current ? activity.approvalCodes : approvalCodes?.find( (aCode: any) => aCode.country === country) ?
                                approvalCodes.map( (aCode: any) => {
                                    if (aCode.country === country)
                                        aCode.approvalCode = result.approvalCode;
                                    return aCode
                                })
                                : [...approvalCodes, {country, approvalCode: result.approvalCode}]
                        }
                    }
                });
            }

        };
        return (
            <Box id="approvalForm" component="form" onSubmit={handleSubmitCountryVersion} noValidate sx={{ paddingLeft: '20px' }} key={country}>
                    <Grid  sx={{marginTop: '10px'}} >
                        <TextField
                            required
                            id="approvalCode"
                            name="approvalCode"
                            sx={{width: '60%'}}
                            disabled={!isEditor || isArchived}
                            label={`Approval code ${label}`}
                            defaultValue={approvalCode.current}
                            autoComplete='off'
                            variant="standard"
                        />
                        <Button
                            variant="contained"
                            size="small"
                            disabled={!isEditor || isArchived}
                            type="submit"
                            sx={{marginBottom: '0px', mt: '0px', ml: '20px'}}
                            endIcon={<SendIcon />}
                        >{buttonLabel}</Button>
                    </Grid>

            </Box>
        )
    }
    // @ts-ignore
    return (
        <Grid >
                {
                    !activity.approvalCode && !shouldEnableApproval.yes &&
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            color: '#fff',
                            fontSize: '20px',
                            zIndex: 999999,
                            backgroundColor: 'rgba(0, 0, 0, 0.75)', // Semi-transparent black background
                            padding: '20px',
                            borderRadius: '8px',
                            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', // Light shadow for a lifted effect
                            border: '1px solid rgba(255, 255, 255, 0.2)', // Thin white border for better edge definition

                            // Centering logic
                            position: 'fixed',   // or 'absolute' if you want it relative to the nearest positioned ancestor
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)'
                        }}
                    >
                        <strong>Attention!</strong>
                        <p>
                            Veeva number / Approval number cannot be set before all templates are done.
                        </p>
                        <p>
                            Please go to step "Template" and make sure that all templates are set to "Done"
                        </p>
                    </Box>

                }
                {
                    (shouldEnableApproval.yes || activity.approvalCode) &&
                    <StyledPaper elevation={24}>
                        {(activity.templateSuite.type !== 'RepEmail' || activity.countries.length === 1)
                            && <ApprovalFormCountryVersion/>
                        }
                        {
                            activity.templateSuite.type === 'RepEmail' && activity.countries.length > 1 && shouldEnableApproval.countries.map( (country: string) => {
                                return (
                                    <ApprovalFormCountryVersion country={country} />
                                )
                            })
                        }
                    </StyledPaper>
                }
        </Grid>
    );
}
Approval.propTypes = {
    lastMessage: PropTypes.object
};
